import Cart from "../models/cart"
import { Http } from "../http"

const http = new Http()

export function getCart(cartId) {
  return new Promise(resolve => {
    http.get(`carts/${cartId}`, {}, (err, res) => {
      err
        ? resolve(err)
        : resolve({
          state: "success",
          cartInfo: Cart.parseFromJson(res.data)
        })
    })
  })
}

export function createNewCart(itemReference) {
  return new Promise(resolve => {
    http.post(
      "carts",
      {
        items: [{ reference: itemReference }]
      },
      (err, res) => {
        err
          ? resolve(err)
          : resolve({
            state: "success",
            cartInfo: Cart.parseFromJson(res.data)
          })
      }
    )
  })
}

export function updateCart(cartId, email, name, url) {
  return new Promise(resolve => {
    http.put(
      `carts/${cartId}`,
      {
        email,
        name,
        url
      },
      (err, res) => {
        err
          ? resolve(err)
          : resolve({
            state: "success",
            message: "Cart updated succesfully"
          })
      }
    )
  })
}

export function resetExistingCart(cartId) {
  return new Promise(resolve => {
    http.post(`carts/${cartId}/reset`, {}, (err, res) => {
      err
        ? resolve(err)
        : resolve({
          state: "success",
          message: "Cart created succesfully"
        })
    })
  })
}

export function addItemsToCart(cartId, itemsReferenceArray) {
  return new Promise(resolve => {
    http.post(
      `carts/${cartId}/items`,
      {
        items: itemsReferenceArray
      },
      (err, res) => {
        err
          ? resolve(err)
          : resolve({
            state: "success",
            message: "Item(s) added succesfully"
          })
      }
    )
  })
}

export function deleteItemFromCart(cartId, itemReference) {
  return new Promise(resolve => {
    http.delete(`carts/${cartId}/items/${itemReference}`, {}, (err, res) => {
      err
        ? resolve(err)
        : resolve({
          state: "success",
          message: "Item deleted succesfully"
        })
    })
  })
}

export function replaceItemFromCart(cartId, currentItemSku, newItemSku) {
  return new Promise(resolve => {
    http.put(
      `carts/${cartId}/replace`,
      {
        current_item: currentItemSku,
        new_item: newItemSku
      },
      (err, res) => {
        err
          ? resolve(err)
          : resolve({
            state: "success",
            message: "Item changed succesfully"
          })
      }
    )
  })
}

export function getPaymentByCart(cartId) {
  return new Promise(resolve => {
    http.get(`payments/byCart?cart_id=${cartId}`, {}, (err, res) => {
      err
        ? resolve(err)
        : resolve({
          state: "success",
          paymentInfo: res.data
        })
    })
  })
}
