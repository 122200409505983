import Product from "../models/product"
import { Http } from "../http"

const http = new Http()

export function getProductsList(category, subCategory) {
  return new Promise(resolve => {
    http.get(
      "products",
      {
        category,
        sub_category: subCategory,
      },
      (err, res) => {
        err
          ? resolve(err)
          : resolve({
              state: "success",
              productsList: res.data.map(product =>
                Product.parseFromJson(product)
              ),
            })
      }
    )
  })
}

export function getUpSellProductsList(cartId) {
  return new Promise(resolve => {
    http.get(
      "products/up-sell",
      {
        cart_id: cartId,
      },
      (err, res) => {
        err
          ? resolve(err)
          : resolve({
              state: "success",
              productsList: res.data.map(product =>
                Product.parseFromJson(product)
              ),
            })
      }
    )
  })
}

export function gerRelatedProdutsList(cartId) {
  return new Promise(resolve => {
    http.getV2(
      "products/related",
      {
        cart_id: cartId,
      },
      (err, res) => {
        err
          ? resolve(err)
          : resolve({
              state: "success",
              productsList: res.data.map(item => ({
                categoryName: item.category,
                relatedProducts: item.related.map(product =>
                  Product.parseFromJson(product)
                ),
              })),
            })
      }
    )
  })
}
