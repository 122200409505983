class Product {
  constructor(
    amount,
    category,
    currency,
    delivery,
    description,
    id,
    image,
    name,
    popular,
    sku,
    subCategory,
    times
  ) {
    this.amount = amount
    this.category = category
    this.currency = currency
    this.delivery = delivery
    this.description = description
    this.id = id
    this.image = image
    this.name = name
    this.popular = popular
    this.sku = sku
    this.subCategory = subCategory
    this.times = times
  }
}

const parseFromJson = function (json) {
  return new Product(
    json.amount,
    json.category,
    json.currency,
    json.delivery,
    json.description,
    json.id,
    json.image,
    json.name,
    json.popular,
    json.sku,
    json.subCategory,
    json.times
  )
}

const parseFromCart = function (json) {
  return new Product(
    json.unit_price,
    json.category,
    json.currency,
    json.delivery,
    json.item_description,
    json._id,
    json.item_image,
    json.item_name,
    null,
    json.sku,
    json.sub_category,
    json.times
  )
}

export default {
  parseFromJson,
  parseFromCart,
}
