import Product from "./product"

class Cart {
  constructor(
    cartId,
    cartItems,
    userName,
    userEmail,
    url,
    totalAmount,
    totalDiscount,
    subTotal,
    currency
  ) {
    this.cartId = cartId
    this.cartItems = cartItems
    this.userName = userName
    this.userEmail = userEmail
    this.url = url
    this.totalAmount = totalAmount
    this.totalDiscount = totalDiscount
    this.subTotal = subTotal
    this.currency = currency
  }
}

const parseFromJson = function (json) {
  return new Cart(
    json._id,
    json.cart_items.map(product => Product.parseFromCart(product)),
    json.customer_name,
    json.customer_email,
    json.url,
    json.total_amount,
    json.total_discount,
    json.total_items_amount,
    json.currency
  )
}

export default {
  parseFromJson,
}
