/* eslint-disable no-useless-escape */
import "regenerator-runtime/runtime"

export const handleGetCartIdFromCookie = () => {
  const cookie = document.cookie.replace(
    /(?:(?:^|.*;\s*)cart_id\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  )
  return cookie
}
