import "regenerator-runtime/runtime"
import { createNewCart } from "../domain/datasources/carts.remote"

export const handleCreateCart = (itemReference, callback) => {
  async function asyncCall() {
    const response = await createNewCart(itemReference)
    if (response.state === "success") {
      callback(null, response.cartInfo)
    } else {
      callback("Something went wrong")
    }
  }
  return asyncCall()
}
