import "regenerator-runtime/runtime"
import { addItemsToCart } from "../domain/datasources/carts.remote"

export const handleAddItemsToCart = (cartId, itemsArray, callback) => {
  async function asyncCall() {
    const response = await addItemsToCart(cartId, itemsArray)
    if (response.state === "success") {
      callback(null, response.message)
    }
  }
  return asyncCall()
}